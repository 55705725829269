import { Box, Pagination } from "@mui/material";
import MUIDataTable from "mui-datatables";
import PropTypes from "prop-types";
import "./Table.scss";
import { Loader } from "../Loader";

function CustomTable({
  title,
  columns,
  data,
  customOption,
  components,
  paginationServer,
  page,
  totalCount,
  countPerPage,
  onChangePage,
  onChangeCountPerPage,
  pagination = false,
  sort = false,
  isLoading,
  noDataText,
  maxHeight = "auto",
  onTableChange,
  sortOrder,
  rowsPerPageOptions = [10, 15, 20, 25, 30],
  setRowProps,
  onRowClick,
}) {
  const options = {
    selectableRows: "none", // Remove checkboxes
    download: false, // Remove download option from toolbar
    print: false, // Remove print option from toolbar
    search: false, // Remove search bar
    filter: false, // Remove column filters
    viewColumns: false, // Remove "View Columns" option from toolbar
    serverSide: paginationServer,
    page: page,
    count: totalCount,
    rowsPerPage: countPerPage,
    onChangePage: onChangePage,
    onChangeRowsPerPage: onChangeCountPerPage,
    pagination: pagination,
    rowsPerPageOptions: rowsPerPageOptions,
    onRowClick: onRowClick,
    sort: sort,
    tableBodyMaxHeight: maxHeight,
    sortOrder,
    onTableChange,
    responsive: "standard",
    setRowProps,
    customFooter: (count, page, rowsPerPage, _, changePage) => {
      const handleChange = (_, value) => {
        changePage(value - 1);
      };
      if (!pagination) {
        return null;
      }

      return (
        <div className="tableFooter">
          <Pagination
            shape="rounded"
            count={Math.ceil(count / rowsPerPage)}
            onChange={handleChange}
            page={page + 1}
          />
        </div>
      );
    },
    textLabels: {
      body: {
        noMatch: noDataText,
      },
    },
  };

  return (
    <Box className="table_container">
      {isLoading ? (
        <Loader />
      ) : (
        <MUIDataTable
          title={title}
          data={data}
          columns={columns}
          options={{ ...options, ...customOption }}
          components={components}
        />
      )}
    </Box>
  );
}

CustomTable.propTypes = {
  title: PropTypes.string,
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  customOption: PropTypes.object,
  components: PropTypes.object,
  paginationServer: PropTypes.bool,
  page: PropTypes.number,
  totalCount: PropTypes.number,
  countPerPage: PropTypes.number,
  onChangePage: PropTypes.func,
  onChangeCountPerPage: PropTypes.func,
  pagination: PropTypes.bool,
  sort: PropTypes.bool,
  isLoading: PropTypes.bool,
  noDataText: PropTypes.string,
  maxHeight: PropTypes.string,
  onTableChange: PropTypes.func,
  sortOrder: PropTypes.object,
};

export default CustomTable;
