import React, { useState } from "react";
import styles from "./RequestManager.module.scss";
import CustomTable from "../../../components/CustomTable";
import { useDashboard } from "../../../utils/hooks/Dashboard";
import { covertDate } from "../../../utils/helper";
import { useNavigate } from "react-router-dom";

const DocumentsReview = ({ isAdminView }) => {
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [sortOrder, setSortOrder] = useState({
    name: "daysPending",
    direction: "desc",
  });

  const { useGetRequestToReview } = useDashboard();

  const { data: requestToReviewData, isLoading } = useGetRequestToReview(
    page + 1,
    sortOrder.name,
    sortOrder.direction === "asc"
  );

  const setHeaderProp = () => {
    return {
      style: {
        span: {
          justifyContent: "center",
        },
      },
    };
  };

  const columns = [
    {
      name: "clientName",
      label: "Client Name",
      options: {
        sort: true,
      },
    },
    {
      name: "contactName",
      label: "Contact Name",
      options: {
        sort: true,
      },
    },
    {
      name: "docsUploaded",
      label: "Items Pending Review",
      options: {
        sort: true,
        customBodyRender: (value) => {
          return <div style={{ textAlign: "center" }}>{value}</div>;
        },
        setCellHeaderProps: setHeaderProp,
      },
    },
    {
      name: "dateUploaded",
      label: "Most Recent Upload Date",
      options: {
        customBodyRender: (value) => {
          return (
            <div style={{ textAlign: "center" }}>
              {covertDate(value, "YYYY-MM-DDTHH:mm:ss")}
            </div>
          );
        },
        sort: true,
        setCellHeaderProps: setHeaderProp,
      },
    },
    {
      name: "daysPending",
      label: "Days Pending Review",
      options: {
        sort: true,
        customBodyRender: (value) => {
          return <div style={{ textAlign: "center" }}>{value}</div>;
        },
        setCellHeaderProps: setHeaderProp,
      },
    },
  ];

  const handleChangePage = (value) => {
    setPage(value);
  };

  const onTableChange = (action, tableState) => {
    if (action === "sort") {
      setSortOrder(tableState.sortOrder);
    }
  };

  const setRowProps = (row, rowIndex) => {
    const dayPending = requestToReviewData?.results[rowIndex]?.daysPending;
    if (dayPending >= 14) {
      return {
        style: { backgroundColor: "#FBE7E8", cursor: "pointer" },
      };
    }
    if (dayPending < 14 && dayPending > 6) {
      return {
        style: { backgroundColor: "#FEF2E5", cursor: "pointer" },
      };
    }

    return {};
  };

  const onRowClick = (_, rowMeta) => {
    const rowData = requestToReviewData?.results[rowMeta.rowIndex];
    navigate(`/ticket/view/${rowData.ticketId}`);
  };

  return (
    <div className={styles.dashboardContainers}>
      <p className={styles.header}>
        {isAdminView ? "Documents to Review" : "My Documents to Review"}
      </p>
      <CustomTable
        columns={columns}
        data={requestToReviewData?.results || []}
        sort
        countPerPage={5}
        pagination={requestToReviewData?.totalActiveRecords > 5}
        page={page}
        totalCount={requestToReviewData?.totalActiveRecords}
        onChangePage={handleChangePage}
        onTableChange={onTableChange}
        sortOrder={sortOrder}
        paginationServer
        isLoading={isLoading}
        setRowProps={setRowProps}
        onRowClick={onRowClick}
      />
    </div>
  );
};

export default DocumentsReview;
