import React from "react";

export const RequestIcon = () => {
  return (
    <svg
      width="38"
      height="42"
      viewBox="0 0 38 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M31.5 0.166626H6.5C3.04167 0.166626 0.25 2.93746 0.25 6.35413V29.0833C0.25 32.5 3.04167 35.2708 6.5 35.2708H8.08333C9.75 35.2708 11.3333 35.9166 12.5 37.0833L16.0625 40.6041C17.6875 42.2083 20.3333 42.2083 21.9583 40.6041L25.5208 37.0833C26.6875 35.9166 28.2917 35.2708 29.9375 35.2708H31.5C34.9583 35.2708 37.75 32.5 37.75 29.0833V6.35413C37.75 2.93746 34.9583 0.166626 31.5 0.166626ZM19 7.97913C21.6875 7.97913 23.8542 10.1458 23.8542 12.8333C23.8542 15.5208 21.6875 17.6875 19 17.6875C16.3125 17.6875 14.1458 15.5 14.1458 12.8333C14.1458 10.1458 16.3125 7.97913 19 7.97913ZM24.5833 27.375H13.4167C11.7292 27.375 10.75 25.5 11.6875 24.1041C13.1042 22 15.8542 20.5833 19 20.5833C22.1458 20.5833 24.8958 22 26.3125 24.1041C27.25 25.5 26.25 27.375 24.5833 27.375Z"
        fill="#1F9254"
      />
    </svg>
  );
};

export const OverDueRequest = () => {
  return (
    <svg
      width="50"
      height="50"
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M45.3332 33.1666L31.9998 9.16663C30.2082 5.93746 27.729 4.16663 24.9998 4.16663C22.2707 4.16663 19.7915 5.93746 17.9998 9.16663L4.6665 33.1666C2.979 36.2291 2.7915 39.1666 4.14566 41.4791C5.49983 43.7916 8.1665 45.0625 11.6665 45.0625H38.3332C41.8332 45.0625 44.4998 43.7916 45.854 41.4791C47.2082 39.1666 47.0207 36.2083 45.3332 33.1666ZM23.4373 18.75C23.4373 17.8958 24.1457 17.1875 24.9998 17.1875C25.854 17.1875 26.5623 17.8958 26.5623 18.75V29.1666C26.5623 30.0208 25.854 30.7291 24.9998 30.7291C24.1457 30.7291 23.4373 30.0208 23.4373 29.1666V18.75ZM26.479 36.8958C26.3748 36.9791 26.2707 37.0625 26.1665 37.1458C26.0415 37.2291 25.9165 37.2916 25.7915 37.3333C25.6665 37.3958 25.5415 37.4375 25.3957 37.4583C25.2707 37.4791 25.1248 37.5 24.9998 37.5C24.8748 37.5 24.729 37.4791 24.5832 37.4583C24.4582 37.4375 24.3332 37.3958 24.2082 37.3333C24.0832 37.2916 23.9582 37.2291 23.8332 37.1458C23.729 37.0625 23.6248 36.9791 23.5207 36.8958C23.1457 36.5 22.9165 35.9583 22.9165 35.4166C22.9165 34.875 23.1457 34.3333 23.5207 33.9375C23.6248 33.8541 23.729 33.7708 23.8332 33.6875C23.9582 33.6041 24.0832 33.5416 24.2082 33.5C24.3332 33.4375 24.4582 33.3958 24.5832 33.375C24.854 33.3125 25.1457 33.3125 25.3957 33.375C25.5415 33.3958 25.6665 33.4375 25.7915 33.5C25.9165 33.5416 26.0415 33.6041 26.1665 33.6875C26.2707 33.7708 26.3748 33.8541 26.479 33.9375C26.854 34.3333 27.0832 34.875 27.0832 35.4166C27.0832 35.9583 26.854 36.5 26.479 36.8958Z"
        fill="#A30D11"
      />
    </svg>
  );
};

export const DocumentIcon = () => {
  return (
    <svg
      width="50"
      height="50"
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M32.9168 4.60419C32.0627 3.75003 30.5835 4.33336 30.5835 5.52086V12.7917C30.5835 15.8334 33.1668 18.3542 36.3127 18.3542C38.2918 18.375 41.0418 18.375 43.396 18.375C44.5835 18.375 45.2085 16.9792 44.3752 16.1459C41.3752 13.125 36.0002 7.68753 32.9168 4.60419Z"
        fill="#CD6200"
      />
      <path
        d="M42.7085 21.2291H36.6877C31.7502 21.2291 27.7293 17.2083 27.7293 12.2708V6.24996C27.7293 5.10413 26.7918 4.16663 25.646 4.16663H16.8127C10.396 4.16663 5.2085 8.33329 5.2085 15.7708V34.2291C5.2085 41.6666 10.396 45.8333 16.8127 45.8333H33.1877C39.6043 45.8333 44.7918 41.6666 44.7918 34.2291V23.3125C44.7918 22.1666 43.8543 21.2291 42.7085 21.2291ZM23.9585 36.9791H15.6252C14.771 36.9791 14.0627 36.2708 14.0627 35.4166C14.0627 34.5625 14.771 33.8541 15.6252 33.8541H23.9585C24.8127 33.8541 25.521 34.5625 25.521 35.4166C25.521 36.2708 24.8127 36.9791 23.9585 36.9791ZM28.1252 28.6458H15.6252C14.771 28.6458 14.0627 27.9375 14.0627 27.0833C14.0627 26.2291 14.771 25.5208 15.6252 25.5208H28.1252C28.9793 25.5208 29.6877 26.2291 29.6877 27.0833C29.6877 27.9375 28.9793 28.6458 28.1252 28.6458Z"
        fill="#CD6200"
      />
    </svg>
  );
};

export const CreateRequestIcon = () => {
  return (
    <svg
      width="50"
      height="50"
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M35.0417 4.16663H14.9584C10.5209 4.16663 6.91675 7.79163 6.91675 12.2083V41.5625C6.91675 45.3125 9.60425 46.8958 12.8959 45.0833L23.0626 39.4375C24.1459 38.8333 25.8959 38.8333 26.9584 39.4375L37.1251 45.0833C40.4167 46.9166 43.1042 45.3333 43.1042 41.5625V12.2083C43.0834 7.79163 39.4792 4.16663 35.0417 4.16663ZM31.2709 20.3125C29.2501 21.0416 27.1251 21.4166 25.0001 21.4166C22.8751 21.4166 20.7501 21.0416 18.7292 20.3125C17.9167 20.0208 17.5001 19.125 17.7917 18.3125C18.1042 17.5 19.0001 17.0833 19.8126 17.375C23.1667 18.5833 26.8542 18.5833 30.2084 17.375C31.0209 17.0833 31.9167 17.5 32.2084 18.3125C32.5001 19.125 32.0834 20.0208 31.2709 20.3125Z"
        fill="var(--secondary-color)"
      />
    </svg>
  );
};
