import React from 'react'
import styles from './HeaderCard.module.scss'

const HeaderCard = ({icon, value, label, onClick, backgroundColor}) => {
  return (
    <div onClick={onClick} className={styles.headerCardContainer} style={{cursor: onClick ? 'pointer' : 'default', backgroundColor}}>
        <div className={styles.content}>
            {icon}
            <div className={styles.contentText}>
                {value && (<p className={styles.value}>{value}</p>)}
                <p className={styles.label}>{label}</p>
            </div>
        </div>
    </div>
  )
}

export default HeaderCard