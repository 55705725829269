import { getApi, postApi } from "../apis";

export const getDashboardData = (isAdminView) =>
  getApi(`Dashboard/GetMyRequestDetails?isAdminView=${isAdminView}`);

export const getNewRequestData = (data) => postApi("Dashboard", data);

export const getRequestToReview = (
  page,
  sortingColumn,
  ascending,
  isAdminView
) =>
  getApi(
    `Dashboard/RequestToReview?pageNumber=${page}&pageSize=5&sortingColumn=${sortingColumn}&Ascending=${ascending}&isAdminView=${isAdminView}`
  );

export const getNotificationData = (isAdminView) =>
  getApi(`Dashboard/GetNotification?isAdminView=${isAdminView}`);
