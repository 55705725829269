export const initialTicketData = {
  ticketID: "",
  client: "",
  contactPerson: "",
  emailId: "",
  dueDate: null,
  status: {
    label: "Open",
    value: "Open",
  },
  assignee: "",
  ticketIDRDocuments: [],
  taxYear: {
    value: new Date().getFullYear(),
    label: new Date().getFullYear()
  }
};

export const ticketLabelMappingColor = {
  Late: "#A30D11",
  ["Due soon"]: "#CD6200",
  Completed: "#1F9254",
};
export const ticketlabelMappingBackground = {
  Late: "#FBE7E8",
  ["Due soon"]: "#FEF2E5",
  Completed: "#EBF9F1",
};

const currentYear = new Date().getFullYear();

export const yearsArray = Array.from({ length: 6 }, (v, i) => ({
  value: (currentYear - i),
  label: (currentYear - i),
}));


export const archiveTableOptions = [
  {
    label: "Users",
    value: 1,
  },
  {
    label: "Requests",
    value: 2,
  },
  {
    label: "Clients",
    value: 3,
  },
  {
    label: "Client Contacts",
    value: 4,
  },
];