import React, { useEffect } from "react";
import CustomTable from "../../components/CustomTable";
import UnarchiveOutlinedIcon from "@mui/icons-material/UnarchiveOutlined";
import { covertDate, setHeaderProp } from "../../utils/helper";
import { UseUserArchiveListContext } from "../../utils/hooks/Archive";
import { IconButton } from "@mui/material";
import UnarchiveModal from "./UnarchiveModal";
import { TrashIcon } from "../../assets/Icons/TrashIcon";
import DeleteModal from "../../components/DeleteModal";

export default function ArchiveRequestTable({
  onChangePage,
  onChangeCountPerPage,
  handleArchiveTicketListData,
  debouncedSearchValue,
}) {
  const {
    storeArchiveData,
    setStoreArchiveData,
    isArchiveTicketListLoading,
    handleTicketUnarchiving,
    mutateDeleteRequest,
    isDeleteRequestLoading,
  } = UseUserArchiveListContext();

  const filterData = {
    clientName: null,
    ticketStatus: null,
    dueDate: null,
    assignee: null,
  };

  const mode = "list";
  const ticketPage = storeArchiveData.page + 1;
  const ticketPageCount = storeArchiveData.count;
  const ticketSortOrder = { name: "dueDate", direction: "asc" };

  useEffect(() => {
    handleArchiveTicketListData(
      mode,
      ticketPage,
      ticketPageCount,
      ticketSortOrder,
      debouncedSearchValue,
      filterData
    );
  }, [debouncedSearchValue, storeArchiveData.page]);

  let changeBackGround = 9,
    prev = 0,
    toogle = 0;
  const setCellProp = (e) => {
    if (prev === changeBackGround) {
      prev = 0;
      toogle++;
    }
    prev++;

    return {
      style: {
        left: "0",
        zIndex: 100,
        background: toogle % 2 === 0 ? "#e5f5fc" : "#ffffff",
        padding: "0!important",
        color: "var(--primary-color)",
        textTransform: "capitalize",
        textAlign: "center",
        marginLeft: "10px",
        fontWeight: "500",
        marginRight: "10px",
      },
    };
  };

  const handleUnarchiveTicketModal = (value, ticketDetails) => {
    setStoreArchiveData((prevState) => ({
      ...prevState,
      ticketId: ticketDetails.ticketID,
      ticketDetails: ticketDetails,
      modalOpen: value + 1,
    }));
  };

  const handleModalClose = () => {
    setStoreArchiveData((prevState) => ({
      ...prevState,
      modalOpen: false,
      deleteModalOpen: false,
    }));
  };

  const handleTicketListUnarchiving = () => {
    const ticketListData = storeArchiveData.ticketDetails;

    const requestPayload = {
      ticketOwnersDetails: [],
      status: "Active",
    };

    handleTicketUnarchiving(requestPayload, ticketListData.ticketID);
  };

  const handleDeleteRequest = (requestDetails) => {
    setStoreArchiveData((prevState) => ({
      ...prevState,
      ticketId: requestDetails.ticketID,
      deleteModalOpen: true,
    }));
  };

  const columns = [
    {
      name: "ticketID",
      label: "ID",

      options: {
        customBodyRenderLite: (value, tableMeta) => {
          const ticketID =
            storeArchiveData?.ticketArchiveListData?.[value]?.ticketID;
          return <p>{ticketID}</p>;
        },

        setCellProps: setCellProp,
        setCellHeaderProps: setHeaderProp,
      },
    },
    {
      name: "clientName",
      label: "Client Name",

      options: {
        customBodyRenderLite: (value, tableMeta) => {
          const clientName =
            storeArchiveData?.ticketArchiveListData?.[value]?.clientName;
          return <p>{clientName}</p>;
        },
        setCellProps: setCellProp,
        setCellHeaderProps: setHeaderProp,
      },
    },
    {
      name: "ticketStatus",
      label: "Ticket Status",

      options: {
        customBodyRenderLite: (value, tableMeta) => {
          const ticketStatus =
            storeArchiveData?.ticketArchiveListData?.[value]?.ticketStatus;
          return <p>{ticketStatus}</p>;
        },
        setCellProps: setCellProp,
        setCellHeaderProps: setHeaderProp,
      },
    },
    {
      name: "checkList",
      label: "Check List",

      options: {
        customBodyRenderLite: (value, tableMeta) => {
          const checklist =
            storeArchiveData?.ticketArchiveListData?.[value]?.checkList;
          return <p>{checklist}</p>;
        },
        setCellProps: setCellProp,
        setCellHeaderProps: setHeaderProp,
      },
    },
    {
      name: "dueDate",
      label: "Due Date",

      options: {
        customBodyRenderLite: (value, tableMeta) => {
          const dueDate =
            storeArchiveData?.ticketArchiveListData?.[value]?.dueDate;
          return <p>{dueDate}</p>;
        },
        setCellProps: setCellProp,
        setCellHeaderProps: setHeaderProp,
      },
    },
    {
      name: "assignedTo",
      label: "Assigned To",

      options: {
        customBodyRenderLite: (value, tableMeta) => {
          const assignedTo =
            storeArchiveData?.ticketArchiveListData?.[value]?.assignedTo;
          return <p>{assignedTo}</p>;
        },
        setCellProps: setCellProp,
        setCellHeaderProps: setHeaderProp,
      },
    },
    {
      name: "ticketLabel",
      label: "Ticket Labels",
      options: {
        customBodyRenderLite: (value, tableMeta) => {
          const ticketLabel =
            storeArchiveData?.ticketArchiveListData?.[value]?.ticketLabel;
          return <p>{ticketLabel === null ? "NA" : ticketLabel}</p>;
        },
        sort: false,
        setCellProps: setCellProp,
        setCellHeaderProps: setHeaderProp,
      },
    },
    {
      name: "deletedAt",
      label: "Archived Date",
      options: {
        sort: false,
        setCellProps: setCellProp,
        setCellHeaderProps: setHeaderProp,
        customBodyRender: (value) => {
          return <p>{covertDate(value)}</p>;
        },
      },
    },
    {
      name: "action",
      label: "Action",
      options: {
        customBodyRenderLite: (value, tableMeta) => {
          const ticketDetails =
            storeArchiveData?.ticketArchiveListData?.[value];
          return (
            <div>
              <IconButton
                onClick={() => handleUnarchiveTicketModal(value, ticketDetails)}
              >
                <UnarchiveOutlinedIcon />
              </IconButton>
              <IconButton onClick={() => handleDeleteRequest(ticketDetails)}>
                <TrashIcon />
              </IconButton>
            </div>
          );
        },
        sort: false,
        setCellProps: setCellProp,
        setCellHeaderProps: setHeaderProp,
      },
    },
  ];

  return (
    <>
      <UnarchiveModal
        open={storeArchiveData.modalOpen}
        primaryText={`Are you sure want to unarchive this request with ID - "${storeArchiveData.ticketId}"?`}
        handleClose={handleModalClose}
        handleYesClick={handleTicketListUnarchiving}
      />
      <DeleteModal
          open={storeArchiveData.deleteModalOpen}
          handleCancel={handleModalClose}
          handleDeleteClick={() => mutateDeleteRequest(storeArchiveData.ticketId)}
          primaryText={`Are you sure want to delete this request?"`}
          isButtonDisabled={isDeleteRequestLoading}
        />
      <CustomTable
        data={storeArchiveData?.ticketArchiveListData || []}
        columns={columns}
        noDataText="No Data Found"
        isLoading={isArchiveTicketListLoading}
        pagination
        paginationServer
        totalCount={storeArchiveData?.totalActiveRecord || 0}
        page={storeArchiveData.page}
        countPerPage={storeArchiveData.count}
        onChangePage={onChangePage}
        onChangeCountPerPage={onChangeCountPerPage}
      />
    </>
  );
}
