import React from "react";
import CustomButton from "../../components/Button";
import { BasicModal } from "../../components/Modal/Modal";
import { Box } from "@mui/material";
import styles from "./Archive.module.scss";

const UnarchiveModal = ({ open, primaryText, handleClose, handleYesClick }) => {
  return (
    <BasicModal open={open} maxWidth="lg">
      <div className={styles.modalContent}>
        <p>{primaryText}</p>
        <Box sx={{ display: "flex" }}>
          <CustomButton
            sx={{ marginTop: "10px" }}
            style={{ color: "#f00", fontWeight: "500", marginRight: "10px" }}
            variant="text"
            onClick={handleClose}
          >
            Cancel
          </CustomButton>
          <CustomButton sx={{ marginTop: "10px" }} onClick={handleYesClick}>
            Yes
          </CustomButton>
        </Box>
      </div>
    </BasicModal>
  );
};

export default UnarchiveModal;
