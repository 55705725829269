import React, { useContext, createContext, useState } from "react";
import { useQuery } from "react-query";
import {
  getDashboardData,
  getNewRequestData,
  getNotificationData,
  getRequestToReview,
} from "../../controllers/dashboard";
import { TokenExpiry } from "../Auth/TokenExpiry";

const DashboardContext = createContext();

export const useDashboard = () => useContext(DashboardContext);

function useProvideDashboard() {
  const [isAdminView, setIsAdminView] = useState(false);

  const useGetDashboardData = () =>
    useQuery(
      ["dashboard_data", isAdminView],
      () => getDashboardData(isAdminView),
      {
        enabled: true,
        select: (data) => data.data,
        onSuccess: (data) => TokenExpiry(data?.ResponseCode),
        cacheTime: 0,
      }
    );

  const useGetNotificationData = () =>
    useQuery(
      ["notification_data", isAdminView],
      () => getNotificationData(isAdminView),
      {
        enabled: true,
        select: (data) => data.data,
        onSuccess: (data) => TokenExpiry(data?.ResponseCode),
        cacheTime: 0,
      }
    );

  const useGetNewRequestData = (data) =>
    useQuery(["new_request_data", data], () => getNewRequestData(data), {
      enabled: true,
      select: (data) => data.data,
      onSuccess: (data) => TokenExpiry(data?.ResponseCode),
      cacheTime: 0,
    });

  const useGetRequestToReview = (page, sortingColumn, ascending) =>
    useQuery(
      ["request_to_review", page, sortingColumn, ascending],
      () => getRequestToReview(page, sortingColumn, ascending, isAdminView),
      {
        enabled: true,
        select: (data) => data.data,
        onSuccess: (data) => TokenExpiry(data?.ResponseCode),
        cacheTime: 0,
      }
    );

  return {
    useGetDashboardData,
    useGetNewRequestData,
    useGetRequestToReview,
    useGetNotificationData,
    isAdminView,
    setIsAdminView,
  };
}

export function ProviderDashboard({ children }) {
  const dashboardData = useProvideDashboard();
  return (
    <DashboardContext.Provider value={dashboardData}>
      {children}
    </DashboardContext.Provider>
  );
}
