import React from "react";
import ReactApexChart from "react-apexcharts";

const BarChart = ({
  header,
  height,
  colors,
  borderRadius,
  categories,
  series,
  onChartClick
}) => {
  const options = {
    chart: {
      toolbar: {
        show: false,
      },
      height,
      type: "bar",
      events: {
        click: onChartClick
      },
    },
    colors,
    plotOptions: {
      bar: {
        columnWidth: "30%",
        distributed: true,
        borderRadius,
      },
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: false,
    },
    xaxis: {
      categories,
      labels: {
        style: {
          fontSize: "12px",
        },
        show: true,
      },
    },
    grid: {
      show: false,
    },
    yaxis: {
      min: 0,
      forceNiceScale: true,
    },
  };

  return (
    <div>
      {header && <p>{header}</p>}
      <ReactApexChart options={options} series={series} type="bar" height={height} />
    </div>
  );
};

export default BarChart;
