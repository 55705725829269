import { Pagination, TextField } from "@mui/material";
import React, { useState } from "react";
import { SearchIcon } from "../../../assets/Icons/SearchIcon";
import styles from "./RequestManager.module.scss";
import { useDashboard } from "../../../utils/hooks/Dashboard";
import { Loader } from "../../../components/Loader";
import { useNavigate } from "react-router-dom";
import moment from "moment";

const Notification = ({ isAdminView }) => {
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);

  const { useGetNotificationData } = useDashboard();

  const { data, isLoading } = useGetNotificationData();

  const filteredList =
    data?.results?.filter((item) =>
      Object.values(item).some((value) =>
        String(value).toLowerCase().includes(search.toLowerCase())
      )
    ) || [];

  const handleChangePage = (_, value) => {
    setPage(value);
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);
    if (page !== 1) {
      setPage(1);
    }
  };

  const getNotificationTime = (dateString) => {
    const date = moment.utc(dateString).local();
    const now = moment().local();

    if (date.isSame(now, "day")) {
      return date.format("h:mm A");
    } else if (date.isSame(now.clone().subtract(1, "day"), "day")) {
      return `Yesterday ${date.format("h:mm A")}`;
    } else {
      return date.format("MM/DD/YYYY h:mm A");
    }
  };

  return (
    <div className={styles.notificationContainer}>
      <div className={styles.header}>
        <p>{isAdminView ? "Notifications" : "My Notifications"}</p>
        <div className={styles.searchInputContainer}>
          <TextField
            variant="outlined"
            placeholder="Search"
            value={search}
            onChange={handleSearch}
            sx={{
              paddingLeft: "0",
              ".MuiOutlinedInput-root": {
                background: "#ffffff",
                borderRadius: "8px",
              },
              ".MuiOutlinedInput-notchedOutline": {
                borderColor: "#9095a0ff",
              },
              ".Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderColor: "#9095a0ff",
              },
            }}
            InputProps={{
              startAdornment: <SearchIcon />,
            }}
          />
        </div>
      </div>
      <div className={styles.body}>
        {isLoading ? (
          <Loader type="section" />
        ) : (
          <div>
            {filteredList.length === 0 && (
              <div className={styles.noData}>No Data Found</div>
            )}
            {filteredList?.slice((page - 1) * 5, page * 5).map((item) => (
              <div
                className={styles.activityContainer}
                key={item.notificationId}
                onClick={() => navigate(`/ticket/view/${item.ticketId}`)}
              >
                <div className={styles.activityHoverContainer}>
                  <div className={styles.activityContent}>
                    <p className={styles.clientContactName}>
                      {item.clientContactName}
                    </p>
                    <p className={styles.time}>
                      {getNotificationTime(item.createdAt)}
                    </p>
                  </div>
                  <div>
                    <p className={styles.message}>{item.message}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
        {filteredList.length > 5 && (
          <div className={styles.pagination}>
            <Pagination
              count={Math.ceil(filteredList?.length / 5)}
              page={page}
              onChange={handleChangePage}
              shape="rounded"
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default Notification;
