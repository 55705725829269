import React from "react";
import { BarChart } from "../Components/Charts";
import styles from "./RequestManager.module.scss";
import { useNavigate } from "react-router-dom";
// import { Box, Tab } from "@mui/material";
// import { TabContext, TabList, TabPanel } from "@mui/lab";
// import { customTabSx } from "../../../utils/helper";

const RequestChart = ({ data, isAdminView }) => {
  const navigate = useNavigate();
  // const [tabValue, setTabValue] = useState("1");

  const dates = Object.keys(data);
  const values = Object.values(data);

  const series = [
    {
      name: "Request",
      data: values,
    },
  ];
  const categories = ["Overdue", "Today", ...dates.slice(2)];

  const colors = [
    "#A30D11",
    "#CD6200",
    "var(--secondary-color)",
    "var(--secondary-color)",
    "var(--secondary-color)",
    "var(--secondary-color)",
  ];

  const onChartClick = (event, chartContext, config) => {
    if (config.dataPointIndex === 0) {
      navigate("/ticket");
    } else {
      const date = new Date();
      date.setDate(date.getDate() + (config.dataPointIndex - 1));
      const selectedDate = date.toLocaleDateString();

      navigate(`/ticket?dueDate=${selectedDate}`);
    }
  };

  // const handleChange = (_, newValue) => {
  //   setTabValue(newValue);
  // };

  return (
    <div className={styles.dashboardContainers}>
      <p className={styles.header}>
        {isAdminView ? "Request Overview" : "My Request Overview"}
      </p>
      {/* <TabContext value={tabValue}>
        <Box>
          <TabList
            onChange={handleChange}
            TabIndicatorProps={{
              sx: { display: "none" },
            }}
            aria-label="lab API tabs example"
            sx={{ minHeight: "0px" }}
          >
            <Tab sx={customTabSx} label="Request by Due Date" value="1" />
            <Tab label="Request by Status" value="2" sx={customTabSx} />
          </TabList>
        </Box>
        <TabPanel value="1" sx={{ padding: "0px" }}> */}
      <BarChart
        series={series}
        categories={categories}
        colors={colors}
        height={320}
        borderRadius={8}
        onChartClick={onChartClick}
      />
      {/* </TabPanel>
        <TabPanel value="2" sx={{ padding: "0px" }}>
          <BarChart
            series={series}
            categories={categories}
            colors={colors}
            height={350}
            borderRadius={8}
          />
        </TabPanel>
      </TabContext> */}
    </div>
  );
};

export default RequestChart;
