import { hooks } from "./utils/index";
import PropTypes from "prop-types";
function ContextContainer({ children }) {
  return (
    <hooks.ProvideAuth>
      <hooks.ProvideMaster>
        <hooks.ProviderDashboard>
          <hooks.ProvideTicket>
            <hooks.ProvideUser>
              <hooks.ProvideClient>
                <hooks.ProvideSideBar>
                  <hooks.ProviderDocumentList>
                    <hooks.ProvideProfile>
                      <hooks.ProvideUserDetails>
                        <hooks.ProvideResetRequest>
                          <hooks.ProvideResetPassword>
                            <hooks.ProvideReminderRequest>
                              <hooks.ProvideDocumentUpdate>
                                <hooks.ProvideCustomerDetails>
                                  <hooks.ProviderGroupDetails>
                                    <hooks.ProviderClientBulkUpload>
                                      <hooks.ProviderUserBulkUpload>
                                        <hooks.ProviderUserArchiveListDetails>
                                          {children}
                                        </hooks.ProviderUserArchiveListDetails>
                                      </hooks.ProviderUserBulkUpload>
                                    </hooks.ProviderClientBulkUpload>
                                  </hooks.ProviderGroupDetails>
                                </hooks.ProvideCustomerDetails>
                              </hooks.ProvideDocumentUpdate>
                            </hooks.ProvideReminderRequest>
                          </hooks.ProvideResetPassword>
                        </hooks.ProvideResetRequest>
                      </hooks.ProvideUserDetails>
                    </hooks.ProvideProfile>
                  </hooks.ProviderDocumentList>
                </hooks.ProvideSideBar>
              </hooks.ProvideClient>
            </hooks.ProvideUser>
          </hooks.ProvideTicket>
        </hooks.ProviderDashboard>
      </hooks.ProvideMaster>
    </hooks.ProvideAuth>
  );
}

ContextContainer.propTypes = {
  children: PropTypes.node.isRequired,
};
export default ContextContainer;
