import { Grid } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import {
  CreateRequestIcon,
  DocumentIcon,
  OverDueRequest,
  RequestIcon,
} from "../../../assets/Icons/DashboardIcons";
import { Loader } from "../../../components/Loader";
import HeaderCard from "../Components/HeaderCard";
import styles from "../Dashboard.module.scss";
import RequestChart from "./RequestChart";
// import CompletedRequest from "./CompletedRequest";
import DocumentsReview from "./DocumentsReview";
// import RequestUploadChart from "./RequestUploadChart";
import AutoRemainders from "./AutoRemainders";
import Notification from "./Notification";
import { useDashboard } from "../../../utils/hooks/Dashboard";

const RequestManager = () => {
  const navigate = useNavigate();
  const { useGetDashboardData, isAdminView } = useDashboard();

  const { data, isLoading } = useGetDashboardData();

  const dashboardData = data?.result;

  const headerCardData = [
    {
      icon: <RequestIcon />,
      label: isAdminView ? "Total Requests" : "Assigned Requests",
      value: dashboardData?.assignedRequest,
      backgroundColor: "#EBF9F1",
    },
    {
      icon: <DocumentIcon />,
      label: "Items to Review",
      value: dashboardData?.itemsReview,
      backgroundColor: "#FEF2E5",
    },
    {
      icon: <OverDueRequest />,
      label: "Overdue Request",
      value: dashboardData?.overDueRequest,
      backgroundColor: "#FBE7E8",
    },
    {
      icon: <CreateRequestIcon />,
      label: "Create New Request",
      onClick: () => navigate("/ticket/add"),
      backgroundColor: "rgba(var(--secondary-color-rgb), 0.1)",
    },
  ];

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div className={styles.dashboard}>
      <Grid container spacing={3}>
        {headerCardData.map((item) => (
          <Grid item xs={6} md={4} lg={3} key={item.label}>
            <HeaderCard
              icon={item.icon}
              label={item.label}
              value={item.value}
              onClick={item.onClick}
              backgroundColor={item.backgroundColor}
            />
          </Grid>
        ))}
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6.5}>
          <RequestChart
            data={dashboardData?.myOpenRequest || {}}
            isAdminView={isAdminView}
          />
        </Grid>
        <Grid item xs={12} md={5.5}>
          <Notification isAdminView={isAdminView} />
        </Grid>
        <Grid item xs={12} md={5}>
          <AutoRemainders
            data={dashboardData?.upcomingReminders || []}
            isAdminView={isAdminView}
          />
        </Grid>
        <Grid item xs={12} md={7}>
          <DocumentsReview isAdminView={isAdminView} />
        </Grid>
        {/* <Grid container item xs={6.5} spacing={3}>
          <Grid item xs={12}>
            <RequestUploadChart />
          </Grid>
          <Grid item xs={12}>
            <CompletedRequest />
          </Grid>
        </Grid> */}
      </Grid>
    </div>
  );
};

export default RequestManager;
