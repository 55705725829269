import React from "react";
import { useAuth, useDashboard } from "../../utils/hooks";
import Layout from "../../layouts";
import styles from "./Dashboard.module.scss";
import RequestManager from "./RequestManager";
import { styled, Switch } from "@mui/material";

const Dashboard = () => {
  const ViewSwitch = styled(Switch)(() => ({
    "& .MuiSwitch-switchBase.Mui-checked": {
      color: "var(--secondary-color)",
    },
    "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
      backgroundColor: "var(--secondary-color)",
    },
  }));
  const { user } = useAuth();
  const { isAdminView, setIsAdminView } = useDashboard();

  const handleChangeView = (e) => {
    setIsAdminView(e.target.checked);
  };

  return (
    <Layout>
      <div className={styles.dashboardContainer}>
        <div className={styles.dashboardHeader}>
          <p className={styles.headerText}>Dox Dashboard</p>
          {user?.RoleName === "Admin" && (
            <div className={styles.toggleSwitch}>
              <p className={styles.toggleText}>Admin View:</p>
              <ViewSwitch checked={isAdminView} onChange={handleChangeView} />
            </div>
          )}
        </div>
        <RequestManager />
      </div>
    </Layout>
  );
};

export default Dashboard;
