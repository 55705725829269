import React, { useEffect } from "react";
import CustomTable from "../../components/CustomTable";
import UnarchiveOutlinedIcon from "@mui/icons-material/UnarchiveOutlined";
import { covertDate, setHeaderProp } from "../../utils/helper";
import { UseUserArchiveListContext } from "../../utils/hooks/Archive";
import { IconButton } from "@mui/material";
import UnarchiveModal from "./UnarchiveModal";
import { TrashIcon } from "../../assets/Icons/TrashIcon";
import DeleteModal from "../../components/DeleteModal";

export default function ArchiveContactsTable({
  onChangePage,
  onChangeCountPerPage,
  handleClientContactList,
  debouncedSearchValue,
}) {
  const {
    storeArchiveData,
    setStoreArchiveData,
    isEditClientContactLoading,
    handleClientContactUnarchiving,
    mutateDeleteClientContact,
    isDeleteClientContactLoading,
  } = UseUserArchiveListContext();

  useEffect(() => {
    const data = {
      page: storeArchiveData.page + 1,
      tableCount: storeArchiveData.count,
      searchValue: debouncedSearchValue,
    };
    handleClientContactList(data);
  }, [debouncedSearchValue, storeArchiveData.page]);

  let changeBackGround = 9,
    prev = 0,
    toogle = 0;
  const setCellProp = (e) => {
    if (prev === changeBackGround) {
      prev = 0;
      toogle++;
    }
    prev++;

    return {
      style: {
        background: toogle % 2 === 0 ? "#e5f5fc" : "#ffffff",
        left: "0",
        zIndex: 100,
        fontWeight: "500",
        padding: "0!important",
        textTransform: "capitalize",
        color: "var(--primary-color)",
        textAlign: "center",
        marginLeft: "10px",
        marginRight: "10px",
      },
    };
  };

  const handleClientContactUnarchiveModal = (value, clientDetails) => {
    setStoreArchiveData((prevState) => ({
      ...prevState,
      userName: clientDetails.clientName,
      clientContactDetails: clientDetails,
      modalOpen: value + 1,
    }));
  };

  const handleModalClose = () => {
    setStoreArchiveData((prevState) => ({
      ...prevState,
      // userName: "",
      modalOpen: false,
      deleteModalOpen: false,
    }));
  };

  const handleClientContactListUnarchiving = () => {
    const clientListData = storeArchiveData.clientContactDetails;

    const requestPayload = {
      firstName: clientListData.firstName,
      lastName: clientListData.lastName,
      phone: clientListData.phone,
      clientContactId: clientListData.clientContactId,
      isActive: true,
      status: "Active",
    };

    handleClientContactUnarchiving(requestPayload, clientListData.clientId);
  };

  const handleDeleteContact = (contactDetails) => {
    setStoreArchiveData((prevState) => ({
      ...prevState,
      userName: `${contactDetails.firstName} ${contactDetails.lastName}`,
      clientContactDetails: contactDetails,
      deleteModalOpen: true,
    }));
  };

  const columns = [
    {
      name: "clientId",
      label: "Client Id",

      options: {
        customBodyRenderLite: (value, tableMeta) => {
          const clientId =
            storeArchiveData?.clientContactArchiveList?.[value]?.clientId;
          return <p>{clientId}</p>;
        },

        setCellProps: setCellProp,
        setCellHeaderProps: setHeaderProp,
      },
    },
    {
      name: "clientName",
      label: "Client Name",

      options: {
        customBodyRenderLite: (value, tableMeta) => {
          const clientName =
            storeArchiveData?.clientContactArchiveList?.[value]?.clientName;
          return <p>{clientName}</p>;
        },
        setCellProps: setCellProp,
        setCellHeaderProps: setHeaderProp,
      },
    },
    {
      name: "groupName",
      label: "Group Name",

      options: {
        customBodyRenderLite: (value, tableMeta) => {
          const groupName =
            storeArchiveData?.clientContactArchiveList?.[value]?.groupName;
          return <p>{groupName === null ? "NA" : groupName}</p>;
        },
        setCellProps: setCellProp,
        setCellHeaderProps: setHeaderProp,
      },
    },
    {
      name: "fname",
      label: "First Name",

      options: {
        customBodyRenderLite: (value, tableMeta) => {
          const firstName =
            storeArchiveData?.clientContactArchiveList?.[value]?.firstName;
          return <p>{firstName}</p>;
        },
        setCellProps: setCellProp,
        setCellHeaderProps: setHeaderProp,
      },
    },
    {
      name: "lname",
      label: "Last Name",

      options: {
        customBodyRenderLite: (value, tableMeta) => {
          const lastName =
            storeArchiveData?.clientContactArchiveList?.[value]?.lastName;
          return <p>{lastName}</p>;
        },
        setCellProps: setCellProp,
        setCellHeaderProps: setHeaderProp,
      },
    },
    {
      name: "phone",
      label: "Phone",

      options: {
        customBodyRenderLite: (value, tableMeta) => {
          const phone =
            storeArchiveData?.clientContactArchiveList?.[value]?.phone;
          return <p>{phone}</p>;
        },
        setCellProps: setCellProp,
        setCellHeaderProps: setHeaderProp,
      },
    },
    {
      name: "email",
      label: "Email",

      options: {
        customBodyRenderLite: (value, tableMeta) => {
          const email =
            storeArchiveData?.clientContactArchiveList?.[value]?.email;
          return <p>{email}</p>;
        },
        setCellProps: setCellProp,
        setCellHeaderProps: setHeaderProp,
      },
    },
    {
      name: "deletedAt",
      label: "Archived Date",
      options: {
        setCellProps: setCellProp,
        setCellHeaderProps: setHeaderProp,
        customBodyRender: (value) => {
          return <p>{covertDate(value)}</p>;
        },
      },
    },
    {
      name: "action",
      label: "Action",
      options: {
        customBodyRenderLite: (value, tableMeta) => {
          const contactDetails =
            storeArchiveData?.clientContactArchiveList?.[value];
          return (
            <div>
              <IconButton
                onClick={() =>
                  handleClientContactUnarchiveModal(value, contactDetails)
                }
              >
                <UnarchiveOutlinedIcon />
              </IconButton>
              <IconButton onClick={() => handleDeleteContact(contactDetails)}>
                <TrashIcon />
              </IconButton>
            </div>
          );
        },
        sort: false,
        setCellProps: setCellProp,
        setCellHeaderProps: setHeaderProp,
      },
    },
  ];

  return (
    <>
      <UnarchiveModal
        open={storeArchiveData.modalOpen}
        primaryText={`Are you sure you want to unarchive this client contact - "${storeArchiveData.userName}"?`}
        handleClose={handleModalClose}
        handleYesClick={handleClientContactListUnarchiving}
      />
      <DeleteModal
        open={storeArchiveData.deleteModalOpen}
        handleCancel={handleModalClose}
        handleDeleteClick={() =>
          mutateDeleteClientContact(storeArchiveData?.clientContactDetails?.clientContactId)
        }
        primaryText={`Are you sure want to delete this contact-"${storeArchiveData.userName}"?`}
        secondaryText="All the tickets related to this contact will be deleted."
        isButtonDisabled={isDeleteClientContactLoading}
      />
      <CustomTable
        data={storeArchiveData?.clientContactArchiveList || []}
        columns={columns}
        noDataText="No Data Found"
        isLoading={isEditClientContactLoading}
        pagination
        paginationServer
        totalCount={storeArchiveData?.totalActiveRecord || 0}
        page={storeArchiveData.page}
        countPerPage={storeArchiveData.count}
        onChangePage={onChangePage}
        onChangeCountPerPage={onChangeCountPerPage}
      />
    </>
  );
}
