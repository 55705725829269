import React, { useState } from "react";
import styles from "./View-ticket.module.scss";
import CustomTable from "../../../components/CustomTable";
import {
  UseDocumentUpdateContext,
  useDocumentListContext,
} from "../../../utils/hooks";
import VisibilityIcon from "@mui/icons-material/Visibility";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import CustomSwitch from "../../../components/Switch";
import EllipsisText from "react-ellipsis-text";
import PropTypes from "prop-types";
import FileIcon from "../../../assets/Icons/FileIcon";
import MessageIcon from "../../../assets/Icons/MessageIcon";
import CheckBox from "../../../components/Checkbox";
import CustomButton from "../../../components/Button";
import { useParams } from "react-router-dom";
import FilePreviewModal from "../../../components/FilePreviewModal";

const Documentsrequested = React.memo(
  ({ handleFormData, readOnly, handleCheckboxData, ticketData }) => {
    const [showAllFiles, setShowAllFiles] = useState(false);

    const { storeTableData, mutateDownloadZip, isDownloadZipLoading } =
      useDocumentListContext();
    const { fileViewerUrl, setFileViewerUrl } = UseDocumentUpdateContext();

    const { id } = useParams();

    /**
     * Handles the click event for viewing more files.
     * @param {{any}} value - The value associated with the click event.
     * @returns None
     */
    const handleViewMoreClick = (value) => {
      setShowAllFiles((prev) => ({ ...prev, [value]: true }));
    };

    /**
     * Handles the click event for the "View Less" action.
     * @param {{any}} value - The value associated with the click event.
     * @returns None
     */
    const handleViewLessClick = (value) => {
      setShowAllFiles((prev) => ({ ...prev, [value]: false }));
    };

    /**
     * Opens a new window with the file URL to display the document.
     * @param {{fileData}} fileData - An object containing the file URL.
     * @returns None
     */
    const handleDocumentView = (fileData) => {
      const { fileURL } = fileData;
      setFileViewerUrl(fileURL);
    };

    const getSwitchLabel = (tableData, isEmpty) => {
      if (
        tableData.idrDocumentReceived ||
        tableData.elsewhere ||
        tableData.accepted
      ) {
        return "Approved";
      } else if (isEmpty) {
        return "N/A";
      }
      return "Not Approved";
    };

    const onDownloadClick = () => {
      mutateDownloadZip(id);
    };

    const columns = [
      {
        name: "document_type",
        label: "Request Name",
        options: {
          filter: false,
          sort: false,
          customBodyRenderLite: (value) => {
            const data = storeTableData[value];
            return (
              <div className={styles.documentType}>
                <div className={styles.documentTypeIcon}>
                  {data.document_type ? <FileIcon /> : <MessageIcon />}
                </div>
                <p>{data.document_type || data.question}</p>
              </div>
            );
          },
          setCellHeaderProps: () => ({
            style: {
              fontSize: "12px",
              background: "#1D1D1D05",
              borderBottom: "0",
              textAlign: "left",
            },
          }),
          setCellProps: () => ({
            style: {
              left: "0",
              background: "#ffffff",
              textAlign: "center",
              zIndex: 100,
              color: "#4A4A4A",
              padding: "0!important",
              textTransform: "capitalize",
              borderBottom: "1px solid #CCCCCC",
            },
          }),
        },
      },
      {
        name: "documents",
        label: "Uploaded Document",
        options: {
          filter: false,
          sort: false,
          customBodyRenderLite: (value) => {
            const data = storeTableData[value];
            if (data.question) {
              return (
                <div className={styles.requestAnswer}>
                  {data.answers || "Answer not provided"}
                </div>
              );
            }
            return (
              <ul
                className={styles.fileInputWrapper}
                style={
                  data.tickectIDRDocumentVersions.length === 1 ||
                  data.tickectIDRDocumentVersions.length === 2 ||
                  data.tickectIDRDocumentVersions.length === 0
                    ? { height: "auto" }
                    : { height: "170px" }
                }
              >
                {showAllFiles[value]
                  ? data.tickectIDRDocumentVersions.map((item) => (
                      <li key={item?.fileURL}>
                        {item?.fileReference && (
                          <EllipsisText
                            text={item.fileReference}
                            length={30}
                            tooltip={item.fileReference}
                          />
                        )}
                        {!ticketData?.isPurged && (
                          <VisibilityIcon
                            sx={{
                              fontSize: "15px",
                              cursor: "pointer",
                            }}
                            onClick={() => handleDocumentView(item)}
                          />
                        )}
                      </li>
                    ))
                  : data.tickectIDRDocumentVersions.slice(0, 3).map((item) => (
                      <li key={item?.fileURL}>
                        {item?.fileReference && (
                          <EllipsisText
                            text={item.fileReference}
                            length={30}
                            tooltip={item.fileReference}
                          />
                        )}
                        {!ticketData?.isPurged && (
                          <VisibilityIcon
                            sx={{
                              fontSize: "15px",
                              cursor: "pointer",
                            }}
                            onClick={() => handleDocumentView(item)}
                          />
                        )}
                      </li>
                    ))}
                {data.tickectIDRDocumentVersions.length === 0 && (
                  <li>No Documents Uploaded.</li>
                )}
                {data.tickectIDRDocumentVersions.length > 3 &&
                  !showAllFiles[value] && (
                    <li
                      onClick={() => handleViewMoreClick(value)}
                      onKeyDown={() => {}}
                      className={styles.viewMoreData}
                    >
                      <p>
                        View More
                        <UnfoldMoreIcon sx={{ fontSize: "18px" }} />
                      </p>
                    </li>
                  )}
                {showAllFiles[value] && (
                  <li
                    onClick={() => handleViewLessClick(value)}
                    onKeyDown={() => {}}
                    className={styles.viewMoreData}
                  >
                    <p>
                      View Less
                      <UnfoldMoreIcon sx={{ fontSize: "18px" }} />
                    </p>
                  </li>
                )}
              </ul>
            );
          },
          setCellProps: () => ({
            style: {
              left: "0",
              background: "#ffffff",
              zIndex: 100,
              padding: "0!important",
              textTransform: "capitalize",
              textAlign: "left",
              borderBottom: "1px solid #CCCCCC",
            },
          }),
          setCellHeaderProps: () => ({
            style: {
              fontSize: "12px",
              background: "#1D1D1D05",
              borderBottom: "0",
              textAlign: "left",
            },
          }),
        },
      },
      {
        name: "elsewhere",
        label: "Received Elsewhere",
        options: {
          filter: false,
          sort: false,
          customBodyRenderLite: (value) => {
            const data = storeTableData[value];
            const inputWrapperStyles = {
              display: "flex",
              justifyContent: "center",
            };
            const isDisabled =
              (data?.tickectIDRDocumentVersions &&
                data?.tickectIDRDocumentVersions?.length !== 0) ||
              (!data?.tickectIDRDocumentVersions && data?.answers) ||
              readOnly;

            return (
              <div style={inputWrapperStyles}>
                <CheckBox
                  disabled={isDisabled}
                  checked={data?.elsewhere || false}
                  onChange={(e) => handleCheckboxData(data, e.target.checked)}
                />
              </div>
            );
          },
          setCellProps: () => ({
            style: {
              left: "0",
              background: "#ffffff",
              zIndex: 100,
              padding: "0!important",
              textTransform: "capitalize",
              textAlign: "left",
              borderBottom: "1px solid #CCCCCC",
            },
          }),
          setCellHeaderProps: () => ({
            style: {
              fontSize: "12px",
              background: "#1D1D1D05",
              borderBottom: "0",
              textAlign: "center",
            },
          }),
        },
      },
      {
        name: "actions",
        label: "Actions",
        options: {
          filter: false,
          sort: false,
          customBodyRenderLite: (value) => {
            const tableData = storeTableData[value];
            function hasEmptyMultipleDocuments(idrData) {
              if (idrData.elsewhere) {
                return true;
              }
              return idrData?.tickectIDRDocumentVersions?.length === 0;
            }

            const isEmpty = hasEmptyMultipleDocuments(tableData);

            const isNotAnswered = tableData?.questionID && !tableData?.answered;

            const isDisabled = isEmpty || readOnly || isNotAnswered;

            return (
              <CustomSwitch
                disabled={isDisabled}
                checked={
                  tableData.idrDocumentReceived || tableData.accepted || false
                }
                switchLabel={getSwitchLabel(
                  tableData,
                  isEmpty || isNotAnswered
                )}
                handleSwitch={(e) =>
                  handleFormData(tableData, e.target.checked)
                }
              />
            );
          },
          setCellProps: () => ({
            style: {
              left: "0",
              background: "#ffffff",
              zIndex: 100,
              padding: "0!important",
              textTransform: "capitalize",
              textAlign: "center",
              borderBottom: "1px solid #CCCCCC",
            },
          }),
          setCellHeaderProps: () => ({
            style: {
              fontSize: "12px",
              textAlign: "center",
              background: "#1D1D1D05",
              borderBottom: "0",
            },
          }),
        },
      },
    ];

    const hasDocuments = (documents) => {
      for (let doc of documents) {
        if (doc?.tickectIDRDocumentVersions?.length > 0) {
          return true;
        }
      }
      return false;
    };

    return (
      <div className={styles.dataTableWrapper}>
        <div className={styles.document_list_table}>
          <div className={styles.document_list_header}>
            <h5>Documents Requested</h5>
            <CustomButton
              disabled={
                isDownloadZipLoading ||
                !hasDocuments(storeTableData) ||
                ticketData?.isPurged
              }
              onClick={onDownloadClick}
            >
              Download Documents
            </CustomButton>
          </div>
          <CustomTable
            data={storeTableData || []}
            columns={columns}
            noDataText="No Documents Requested"
          />
        </div>
        {fileViewerUrl && (
          <FilePreviewModal
            open={!!fileViewerUrl}
            fileData={fileViewerUrl}
            handleClose={() => setFileViewerUrl(undefined)}
          />
        )}
      </div>
    );
  }
);
Documentsrequested.propTypes = {
  handleFormData: PropTypes.func,
  handleDocumentRequested: PropTypes.func,
  readOnly: PropTypes.bool,
};

export default Documentsrequested;
