import React from "react";
import styles from "./RequestManager.module.scss";
import CustomTable from "../../../components/CustomTable";
import { covertDate } from "../../../utils/helper";
import { useNavigate } from "react-router-dom";

const AutoReminders = ({ data, isAdminView }) => {
  const navigate = useNavigate();

  const columns = [
    {
      name: "clientName",
      label: "Client Name",
    },
    {
      name: "contactName",
      label: "Contact Name",
    },
    {
      name: "scheduledDate",
      label: "Date Scheduled",
      options: {
        customBodyRender: (value) => {
          return (
            <div style={{ textAlign: "center" }}>
              {covertDate(value, "YYYY-MM-DDTHH:mm:ss")}
            </div>
          );
        },
      },
    },
    {
      name: "requestStatus",
      label: "Request Status",
      options: {
        customBodyRender: (value) => (
          <div style={{ textAlign: "center" }}>{value}</div>
        ),
      },
    },
  ];

  const setRowProps = () => ({
    style: { cursor: "pointer" },
  });

  const onRowClick = (_, rowMeta) => {
    const rowData = data[rowMeta.rowIndex];
    navigate(`/ticket/view/${rowData.ticketId}`);
  };

  return (
    <div className={styles.dashboardContainers}>
      <p className={styles.header}>
        {isAdminView ? "Upcoming Auto Reminders" : "My Upcoming Auto Reminders"}
      </p>
      <CustomTable
        columns={columns}
        data={data}
        rowsPerPageOptions={[5]}
        countPerPage={5}
        pagination={data.length > 5}
        onRowClick={onRowClick}
        setRowProps={setRowProps}
      />
    </div>
  );
};

export default AutoReminders;
